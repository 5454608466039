import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Form from 'react-bootstrap/Form';
import { Button } from 'react-admin';
import { EditButton } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
// import PopUp from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import PopUp from './EditStakeFarmPool';
import styled from "styled-components";
import './Style.css';
const API_URL= process.env.REACT_APP_API;
// const API_URL="http://localhost:9000/api/v1"


const StakePoolList = () => {
  const [Pools, setPools] = useState();
  const [isCheckBoxClicked, setCheckBoxClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUp, setShowPopUp] = useState(false);
  const [PoolId, setPoolId] = useState(''); 
  const [PoolData, setPoolData] = useState([]);
  const [PoolIdData, setPoolIdData] = useState(null);
  const [LoggedInAddress, setLoggedInAddress] = useState([]);
  const [superAdminAddress, setSuperAdminAdd] = useState(null);
  // const [isPoolDisabled, setPoolDisabled] = useState(false);
  const [poolStatus, setPoolStatus] = useState();
  const [hidePool, setHidePool] = useState(false);
  // const []
   const [loggedInUserRole, setloggedInUserRole]=useState("");
   const [loggedInUserID, setloggedInUserID] = useState("");
  
  const handleEditButton = (PoolID) => {
        setPoolId(PoolID);
        getPoolById(PoolID) 
        setCheckBoxClicked(!isCheckBoxClicked);
        // console.log(isCheckBoxClicked)
        console.log('Pool ID ==> ',PoolId);
  } 

  
  const getPoolById = (PoolID) => {

    const baseURL2 = `${API_URL}/stakeFarmRoute/getPoolById/${PoolID}`;
    // const baseURL2 = `http://localhost:9000/api/v1/stakeFarmRoute/getPoolById/${PoolID}`;

    // console.log('getPollById==>',PoolID);
    // if(PoolID){
          axios.get(baseURL2, options).then((res) => {
            // if(res){
              const poolById = res.data.data;
              setPoolIdData(poolById[0]); 
              console.log('Pool Data By ID ==>',PoolIdData);
              return PoolIdData;
              // return PoolIdData;
            // }
          }).catch((err)=>{console.log(err);});
        // }
}

  const handleDisablePool = async(e) => {
    e.preventDefault();
      // const updateURL = `http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/updatePool/${PoolId}`;
      const updateURL = `${API_URL}/api/v1/stakeFarmRoute/updatePool/${PoolId}`;

      // console.log('Id for update ==> ', PoolId);
      // const updateURL = `http://localhost:9000/api/v1/stakeFarmRoute/updatePool/${PoolId}`;

      await axios.put(updateURL, options).then((res) => {
        if(res){
          // console.log('Current status value',res.data.data.isDisabled)
          setHidePool(true);
          setPoolStatus(res.data.data.isDisabled);
          setCheckBoxClicked(false);
        }
      }).catch((err)=> {
        console.log(err);
      })
  }

  // const togglePopup = () => {
  //   setIsOpen(!isOpen);
  // }

  const displayPopUp = () => {
    setShowPopUp(!isPopUp);
    
  }


//get logged in data from localstorage

const getLocalData = () => {
    const storedLogID = localStorage.getItem('loggedInID');
    // console.log('This is stored data ====> ', storedLogID);
    setloggedInUserID(storedLogID);
    // console.log('This is stored ID by useState ====> ', loggedInUserID);
    
    const storedLogRole = localStorage.getItem('roleName');
    // console.log('This is stored data ====> ', storedLogRole);
    setloggedInUserRole(localStorage.getItem('roleName'));
    // console.log('This is stored ROLE by useState ====> ', loggedInUserRole);
}

// const baseURL = "http://localhost:9000/api/v1/stakeFarmRoute/getAllStakePools"
// const baseURL = `http://localhost:9000/api/v1/stakeFarmRoute/getAdminStakePools/${loggedInUserID}`




  const baseURL = loggedInUserRole === "SUPERADMIN" ? `${API_URL}/stakeFarmRoute/getAllStakePools` : 
  loggedInUserRole === "ADMIN" ? `${API_URL}/stakeFarmRoute/getAdminStakePools/${loggedInUserID}` : 
                            "Sorry, no api available" ;


  // const baseURL = "http://localhost:9000/api/v1/stakeFarmRoute/getStakePools";

  // const getPoolByIDApi = (PoolId) => {  
  //   const baseURL2 = `http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/getPoolById/${PoolId}`;
  // } 

  // const superAdminURL = 'http://indexapi.43.204.181.147.nip.io/api/v1/SuperAdmin/getSuperAdmin';
  const superAdminURL = `${API_URL}/SuperAdmin/getSuperAdmin`;

  const options = {
    mode: "cors"
  }

  useEffect(() => {
  const fetchData = () => {
    axios.get(baseURL, options).then((res) => {
      const poolData = res.data.data;
      console.log('---->>>>',poolData)
      setPools(poolData);
      console.log(Pools);
      if(isCheckBoxClicked){
        // console.log(' Pool Status==> ', PoolData);
      }
    });
  }}, []);

 const baseURL5 = `${API_URL}/SuperAdmin/status`;
  //getCurrent Logged In Admin User
  const getCurrentAdminUser = () => {
    axios.get(baseURL5).then((res) => {
      // console.log('Current Logged In Admin User ---------> ', res);
    });
  };


  const getSuperAdmin = () => {
    axios.get(superAdminURL, options).then((res) => {
      // setSuperAdminAdd(res);
      // console.log(res)
      const data = res.data.data
      setSuperAdminAdd(data[0].superAdminWallet);

      // console.log('Address from database==>',superAdminAddress);
  
    }).then(err => {
      console.log(err);
    })
  }

const getLoggedInAddress = () => {
  if(window.ethereum){
    window.ethereum.request({method:'eth_requestAccounts'})
      .then(res=>{
        // Return the address of the wallet
        setLoggedInAddress(res);
        // console.log(res[0]) 
        // console.log('Address from Metamask==>',LoggedInAddress[0]);
      }).catch(err=>{
        console.log(err)
      })
  }else{
    alert("install metamask extension!!")
  }
}

  // useEffect(()=>{
  //   fetchData();
  // }, []);

  useEffect(() => {
     getLocalData(); 
  }, []);

  // useEffect(()=>{
  //   getLoggedInAddress();
  // }, []);



  useEffect(() => {
    getSuperAdmin();
    // fetchData();
    getLoggedInAddress();
    // getCurrentUser();
    // getPoolStatus();
    // getPoolById(PoolId);  
  }, []);

  useEffect (() => {
    getPoolById(PoolId); 
    getCurrentAdminUser();
    // getCurrentUser();
  }, [])

  return (


    //toDo --> superadmin and loggedin wallet address same or not?

    <div>
      <Table striped bordered hover >
        <thead class="text-center">
          <tr>
            <th></th>
            <th>Name</th>
            <th>logo</th>
            <th>Contract Address</th>
            <th>Lock Duration</th>
          </tr>
        </thead>
        {Pools.map((Pools) => (
          <tbody key={Pools.id} className="text-center" > 
            <tr className={hidePool && PoolId == Pools.id  ?  "disable"   : ''}>
            <td> {<Form.Check aria-label="option 1" onClick={async() => await handleEditButton(Pools.id)} />} </td>
             
              <td> {Pools.name} </td>
              <td>
                <img src={Pools.logo}  style={{width:'50px',height:'50px'}}/>
              </td>
              <td> {Pools.contractAddress} </td>
              <td> {Pools.lock_duration} </td>
            </tr>
          </tbody>
        ))}
      </Table>
      
      <div>
          { isCheckBoxClicked ? <>
            <div style={{display:'flex', flexDirection: 'row'}}>
            <SubmitBtn>
              {' '}
              <button type='submit' onClick={displayPopUp}>Edit Pool</button>{' '}
            </SubmitBtn>

            <SubmitBtn>
              {' '}
              <button type='submit' onClick={handleDisablePool}>Disable Pool</button>{' '}
            </SubmitBtn>
            </div>
            
              <div>{isPopUp ? <><PopUp DataByID={PoolIdData} poolID={PoolId} handleClose={displayPopUp}/></> : ''}</div>
          </>  : ''}
      </div>
    </div>
  );
};

const SubmitBtn = styled.div`
  cursor: pointer;
  background-color: #2196f3;
  //   height: 1.5rem;
  width: 10%;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  border: 1px solid #030e67;
  padding: 5px;
  button {
    background: none;
    border: none;
    font-weight: 700;
    font-family: 'Rajdhani';
  }
`

export default StakePoolList;