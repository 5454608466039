import axios from 'axios'
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import styled from "styled-components";
import { useEffect } from 'react'
import { useNotify } from 'react-admin'

const API_URL= process.env.REACT_APP_API;
  
const PopUp = (props) => {
  const inputStyle = {
    width: '64%',
    height: '50px',
    margin: '10px',
    outline: 'none',
    border: 'none' // border-radius: "5px"
  }

  // const [selectValue, setSelectValue] = useState('Pool Type');
  const [itWorks, setItWorks] = useState(false)

  const [formvalue, setFormvalue] = useState({
    name: '',
    logo: '',
    contractAddress: '',
    PoolType: '',
    lpTokenAddress: ''      
  })
  const [formerror, setFormerror] = useState({})
  const [issubmit, setsubmit] = useState(false)

  const [PoolData, setPoolData] = useState([]);

  const [isDataById, setDataByID] = useState(false);

  const notify = useNotify()

  function handleClicked () {
    setItWorks(true)
  }

  function handleToggleClicked () {
    setItWorks(false)
  }

  const handleSuccess = () => {
    notify(`Form submitted`, `success`)
  }

  const handleError = (err) => {
    if(err){
      notify(`Form not submitted`, `error`)
    }
  }

const  displayDataById = () =>{
  if(props.DataByID){
    setDataByID(true);
    console.log('editDataById==>', props.DataByID );
  }else{
    console.log('Unable to found Data by ID');
  }
} 
  
  // HandelValidation
  const HandelValidation = e => {
    const { name, value } = e.target
    setFormvalue({ ...formvalue, [name]: value })
    console.log(formvalue);
  }

  const baseUrl =
    `${API_URL}/stakeFarmRoute/addStakePool`;
    // 'http://localhost:7000/api/v1/stakeFarmRoute/addStakePool'

    const baseURL2 = `${API_URL}/stakeFarmRoute/getPoolById/${props.poolID}`;
    // const baseURL2 = `http://localhost:9000/api/v1/stakeFarmRoute/getPoolById/${props.poolID}`;

    // const fetchDataById = () => {
    //   // if(props.poolID && props.IsPopUp ){
    //     axios.get(baseURL2).then((res) => {
    //       console.log('res==>',res.data.data);
    //       // const myUser = res.data.data[0];
    //       // console.log(" My USER ==> ", myUser);
    //       setPoolData(res.data.data[0]);
    //       console.log(' databy id ==> ',PoolData);
    //     });
    //   // }
    // };
  
    //updatePoolData

      const handleSubmit = e => {
        e.preventDefault()
        e.target.reset()
        setFormvalue('')
        // setFormerror(validationform(formvalue));
        setsubmit(true)
    
        axios
          .put(  
            `${API_URL}/stakeFarmRoute/updatePoolData/${props.poolID}`, //To fetch Api
            // `http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/updatePoolData/${props.poolID}`,
            {
              name: formvalue.name || props.DataByID.name,
              logo: formvalue.logo || props.DataByID.logo,
              contractAddress: formvalue.contractAddress || props.DataByID.contractAddress,
              PoolType: formvalue.PoolType || props.DataByID.PoolType,
              lpTokenAddress: formvalue.lpTokenAddress || props.DataByID.lpTokenAddress,
            }
          )
          .then(res => {
            handleSuccess();
            console.log("success, dictionary sent,", res);
          })
          .catch(err => {
            console.log(err.response)
            handleError(err);
          })
      }
    


  //   const handleSubmit = async e => {
  //     e.preventDefault();

  //     const editDataById = async () => {
  //         try {
  //             const response = await axios.put(`http://localhost:9000/api/v1/stakeFarmRoute/updatePoolData/${props.poolID}`, {
  //                 method: 'PUT',
  //                 body: JSON.stringify({
  //                   name: formvalue.name,
  //                   logo: formvalue.logo,
  //                   contractAddress: formvalue.contractAddress,
  //                   PoolType: formvalue.PoolType,
  //                   lpTokenAddress: formvalue.lpTokenAddress
  //                 }),
  //                 headers: {
  //                     "Content-type": "application/json; charset=UTF-8"
  //                 }
  //             })
  //                 .then(response => response)
  //                 .then(json => console.log(json));
  //             console.warn(response.data);
  //         } catch (error) {
  //             console.warn(error);
  //         }
  //     };
  //     editDataById();
  // };


  //   HandelSubmit function
  // const handleSubmit = e => {
  //   e.preventDefault()
  //   e.target.reset()
  //   setFormvalue('')
  //   // setFormerror(validationform(formvalue));
    
  //   updatePoolData();
    
  //   setsubmit(true)

    // axios
    //   .post(  
    //     baseURL2, //To fetch Api
    //     {
    //       name: formvalue.name,
    //       logo: formvalue.logo,
    //     }
    //   )
    //   .then(res => {
    //     handleClick();
    //     console.log("success, dictionary sent,", res);
    //   })
    //   .catch(err => {
    //     console.log(err.response)
    //     handleError(err);
    //   })
  // }

  useEffect(() => {
    // if (Object.keys(formerror).length === 0 && issubmit) {
    //     console.log('Form Values',formvalue);
    // }
      // fetchDataById();
      displayDataById();
  }, [formerror, formvalue, issubmit]);   


  return (
    <PopUpBox className="popup-box">
      <Box className="box">
        <CloseIcon className="close-icon" onClick={props.handleClose}>x</CloseIcon>
        <form onSubmit={handleSubmit}>
        <FlexDiv>
          <ContainerDiv>

            <WrapContainer>
              <Label>Name :</Label>
              <Input>
                <input
                  type='name'
                  className='inputtag'
                  name='name'
                  onChange={HandelValidation}
                  value={formvalue.name || props.DataByID.name}
                  // defaultValue={props.DataByID.name || ''}
                  // value={props.DataByID.name}
                /> 
              </Input>
            </WrapContainer>

            <WrapContainer>
              <Label>Logo : </Label>
              <Input>
                <input
                  type='logo'
                  className='inputtag'
                  name='logo'
                  onChange={HandelValidation}
                  value={formvalue.logo || props.DataByID.logo} 
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
              <Label>Contract Address :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='contractAddress'
                  onChange={HandelValidation}
                  value={formvalue.contractAddress || props.DataByID.contractAddress}
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
            <Label>Pool Type :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='PoolType'
                  onChange={HandelValidation}
                  value={formvalue.PoolType || props.DataByID.PoolType}
                  placeholder="Enter Pool Type only as stake or farm"
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
              <Label>LP Token Address :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='lpTokenAddress'
                  onChange={HandelValidation}
                  value={formvalue.lpTokenAddress || props.DataByID.lpTokenAddress}
                />
              </Input>
            </WrapContainer>

            {itWorks ? (
              <>
                {/* <Label>LP TokenAddress :</Label>
                <input
                  type='text'
                  className="inputtag"
                  style={inputStyle}
                  name="lpTokenAdderss"
                  onChange={HandelValidation}
                  value={formvalue.lpTokenAddress}
                /> */}
            <WrapContainer>
              <Label>LP Token Address :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='lpTokenAddress'
                  onChange={HandelValidation}
                  // value={formvalue.lpTokenAddress}
                  defaultValue={props.DataByID.lpTokenAddress || ''}
                />
              </Input>
            </WrapContainer>
              </>
            ) : (
              ''
            )}

            {/* <WrapContainer>
              <Label>Pool Type :</Label>
              <Select defaultValue={selectValue} >
                <option value="Orange">Stake</option>
                <option value="Radish">Farm</option>
              </Select>
            </WrapContainer> */}

            <SubmitBtn>
              {' '}
              <button type='submit'>Submit</button>{' '}
            </SubmitBtn>
          </ContainerDiv>
        </FlexDiv>
      </form>
      </Box>
    </PopUpBox>
  );
};

const PopUpBox = styled.div`
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
`
const Box = styled.div`
    width: 70%;
    margin: 0 auto;
    position: relative;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
`

const CloseIcon = styled.span`
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
`;

//  css start styled components
const FlexDiv = styled.div`
  background: #b2c8df;
  border-radius: 3px;
  border: 2px solid #2196f3;
  margin: 5em 1em;
  padding: 0.25em 1em;
  // width:100%;
  height: auto;
`

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .message {
    font-size: 1.2em;
    text-align: center;
    color: #36a936;
  }
`
const WrapContainer = styled.div`
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
`
const Label = styled.div`
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
`

const Input = styled.div`
  .inputtag {
    width: 500%;
    height: 50px;
    margin: 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    :focus {
      border: 1px solid #2196f3;
    }
  }
  .radiotag {
  }
`

const RadioBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  .input {
    background-color: red;
  }
`

const SubmitBtn = styled.div`
  cursor: pointer;
  background-color: #2196f3;
  //   height: 1.5rem;
  width: auto;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  border: 1px solid #030e67;
  padding: 5px;
  button {
    background: none;
    border: none;
    font-weight: 700;
    font-family: 'Rajdhani';
  }
`

// const Select = styled.select`
//   // width: 100%;
//   // height: 35px;
//   // background: white;
//   // color: gray;
//   // padding-left: 5px;
//   // font-size: 14px;
//   // border: none;
//   // margin-left: 10px;

//   width: 500%;
//     height: 50px;
//     margin: 10px;
//     outline: none;
//     border: none;
//     border-radius: 5px;
//     :focus {
//       border: 1px solid #2196f3;
//     }

//   option {
//     color: black;
//     background: white;
//     display: flex;
//     white-space: pre;
//     min-height: 20px;
//     padding: 0px 2px 1px;
//   }
// `;


export default PopUp;
