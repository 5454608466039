import * as React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { SimpleForm, BooleanInput, useNotify } from 'react-admin'
import { useState, useEffect } from 'react'
import { actions } from '../../actions'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import stakeABI from '../../contractData/abis/feStaking.json'
import farmABI from '../../contractData/abis/farming.json'
import 'bootstrap/dist/css/bootstrap.min.css'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-admin'
import { EditButton } from 'react-admin'
import UserIcon from '@material-ui/icons/Group'
import Web3 from 'web3'


const API_URL= process.env.REACT_APP_API;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // textAlign:'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 230,
    height: 180,
    backgroundColor: '#2196f3',
    // border:'1px solid #2196f3',
    // marginRight: '20px',
    boxShadow: '5px 5px 5px 2px rgba(0, 0, 0, 0.3)'
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '100px'
    // backgroundColor:'black'
  },

  //container class
  container: {
    display: 'flex',
    flexDirection: 'column'
  },

  // second:{

  // }

  accMain: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },

  hr: {
    marginTop: '5px'
  },

  HGreen: {
    // color: 'green'
  },

  HRed: {
    // color: 'red'
  },

  subDiv1: {
    marginTop: '50px'
  },

  subDiv2: {
    marginTop: '50px'
  }
}))

const Dashoard = props => {
  const notify = useNotify()

  const classes = useStyles()

  const [farmData, setFarmData] = useState([])
  const [stakeData, setStakeData] = useState([])
  const [farmCount, setFarmCount] = useState(0)
  const [stakeCount, setStakeCount] = useState(0)
  const [stakedUsers, setStakeUsers] = useState(0)
  const [farmedUsers, setFarmedUsers] = useState(0)

  const [expanded, setExpanded] = React.useState(false)
  const [totalParticipants, setTotalParticipants] = useState([])
  const [count, setCount] = useState(0)
  const [hiddenPools, setHiddenPools] = useState([])
  const [hiddenPools1, setHiddenPools1] = useState([])
  const [activePools, setActivePools] = useState([]);
  const [loggedInUserRole, setloggedInUserRole]=useState("");
  const [loggedInUserID, setloggedInUserID] = useState("");
  const [activePools1,setActivePools1] = useState("")

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const options = {
    mode: 'cors'
  }

  // const farmURL =
  //   'http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/getAllFarmPools'

  // const stakeURL =
  //   'http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/getAllStakePools'
  


// Base URL for staking (staking pool api)...........................................
  const baseURL = loggedInUserRole === "SUPERADMIN" ? `${API_URL}/stakeFarmRoute/getAllStakePools` : 
  loggedInUserRole === "ADMIN" ? `${API_URL}/stakeFarmRoute/getAdminStakePools/${loggedInUserID}` : 
                            "Sorry, no api available" ;

   
    // Base URL for farming (farm pool api)...........................................
   const baseURL1 = loggedInUserRole === "SUPERADMIN" ? `${API_URL}/stakeFarmRoute/getAllFarmPools`:
loggedInUserRole === "ADMIN" ? `${API_URL}/stakeFarmRoute/getAdminFarmPools/${loggedInUserID}` : 
                          "Sorry, no api available" ;



  const RPC_NODE =
    process.env.NODE_ENV === 'PRODUCTION'
      ? 'https://rpc.ankr.com/bsc'
      : 'https://rpc.ankr.com/bsc_testnet_chapel'

  const web3 = new Web3(RPC_NODE);

  // const fetchFarmData = () => {
  //   axios.get(farmURL, options).then(res => {
  //     console.log('Farm Pools', res.data.data);
  //     const farm = res.data.data;
  //     setFarmData(farm);
  //     setFarmCount(farm.length);
  //     console.log(farmCount);
  //   })
  // }

  // const fetchStakeData = () => {
  //   axios.get(stakeURL, options).then(res => {
  //     console.log('Stake Pools', res.data.data)
  //     const stake = res.data.data
  //     console.log('stakeData', stake)
  //     setStakeData(stake)
  //     setStakeCount(stake.length)
  //     console.log(stakeCount)
  //   })
  // }

  const stakeParticipants = () => {
    stakeData.map(async (currentValue, i) => {
      if (currentValue.length - 1) {
        console.log(`==>`, currentValue.contractAddress)
      }

      const contract = new web3.eth.Contract(
        stakeABI,
        currentValue.contractAddress
      )
      // let count = [];
      const stakeUserCount = await contract.methods.totalParticipants().call()
      console.log('stakeUserCount ==> ', stakeUserCount)
      setTotalParticipants([])
      totalParticipants.push(stakeUserCount)
      console.log('current-length==>', i)
      if (i === 5) {
        console.log('conditional outputs ==> ', totalParticipants)
        return totalParticipants
      }
      // console.log('total==> ', totalParticipants);
    })
  }

  const Count = async () => {
    if (totalParticipants.length !== 0) {
      let sum = await totalParticipants.reduce(function (
        previousValue,
        currentValue
      ) {
        return previousValue + currentValue
        // console.log('sum', previousValue + currentValue);
      })
      console.log('sum ==> ', sum)
    }
  }

  //..............................................get  hidden stake pools....................................................
  const getHiddenPools = () => {
    const baseURL4 = loggedInUserRole === "SUPERADMIN" ? `${API_URL}/stakeFarmRoute/getHiddenStakePools` : 
    loggedInUserRole === "ADMIN" ? `${API_URL}/getAdminFarmPools/${loggedInUserID}` : 
                              "" ;
  
    axios
      .get(baseURL4, options)
      .then(res => {
        console.log('Hidden Pools', res.data.data)
        setHiddenPools(res.data.data)
      })
      .catch(err => {
        console.log('Errors', err)
      })
  }

  // .........................................get hidden farm pools.....................................................
  const getHiddenPools1 = () => {
    const baseURL4 =loggedInUserRole === "SUPERADMIN" ? `${API_URL}/stakeFarmRoute/getAllFarmPools`:
    loggedInUserRole === "ADMIN" ? `${API_URL}/stakeFarmRoute/getAdminStakePools/${loggedInUserID}` : 
                              "Sorry, no api available" ;
    
  
    axios
      .get(baseURL4, options)
      .then(res => {
        console.log('Hidden Pools', res.data.data)
        setHiddenPools1(res.data.data)
      })
      .catch(err => {
        console.log('Errors', err)
      })
  }



  //.....................get active stake  pools.........................................//
  const getActivePools = () => {
    const baseURL = loggedInUserRole === "SUPERADMIN" ? `${API_URL}/stakeFarmRoute/getAllStakePools` : 
    loggedInUserRole === "ADMIN" ? `${API_URL}/stakeFarmRoute/getAdminStakePools/${loggedInUserID}` : 
                              "Sorry, no api available" ;
  
    axios
      .get(baseURL, options)
      .then(res => {
        console.log('Hidden Pools', res.data.data)
        setActivePools(res.data.data)
      })
      .catch(err => {
        console.log('Errors', err)
      })
  }

  // ................................. get active farm pools...........................................//
  const getActivePools1 = () => {
  const baseURL = loggedInUserRole === "SUPERADMIN" ? `${API_URL}/stakeFarmRoute/getAllFarmPools`:
loggedInUserRole === "ADMIN" ? `${API_URL}/stakeFarmRoute/getAdminFarmPools/${loggedInUserID}` : 
                          "" ;

    axios
      .get(baseURL, options)
      .then(res => {
        console.log('Hidden Pools', res.data.data)
        setActivePools1(res.data.data)
      })
      .catch(err => {
        console.log('Errors', err)
      })
    }

 

  // console.log(totalParticipants);
  // useEffect(() => {
  //   stakeParticipants();
  //   Count();
  // }, [totalParticipants]);

  useEffect(() => {
    // fetchFarmData()
    // fetchStakeData()
    getHiddenPools()
    getHiddenPools1()
    getActivePools()
    getActivePools1()
    stakeParticipants()
    Count()
  }, [loggedInUserID,loggedInUserRole])

 // ................................ use Effect for staking...........................//
  useEffect(() => {
    const storedLogID = localStorage.getItem('loggedInID');
    console.log('This is stored data ====> ', storedLogID);
    setloggedInUserID(storedLogID);
    console.log('This is stored ID by useState ====> ', loggedInUserID);
    
    const storedLogRole = localStorage.getItem('roleName');
    console.log('This is stored data ====> ', storedLogRole);
    setloggedInUserRole(storedLogRole);
    console.log('This is stored ROLE by useState ====> ', loggedInUserRole);
  }, [loggedInUserID, loggedInUserRole])


useEffect(() => {
  axios.get(baseURL, options).then(res => {
         console.log('Stake Pools', res.data.data)
        const stake = res.data.data
        console.log('stakeData', stake)
         setStakeData(stake)
         setStakeCount(stake.length)
         console.log(stakeCount)
       })
  }, [loggedInUserID, loggedInUserRole])


//  .................................use Effect for farming..........................//

useEffect(() => {
  const storedLogID = localStorage.getItem('loggedInID');
  console.log('This is stored data ====> ', storedLogID);
  setloggedInUserID(storedLogID);
  console.log('This is stored ID by useState ====> ', loggedInUserID);
  
  const storedLogRole = localStorage.getItem('roleName');
  console.log('This is stored data ====> ', storedLogRole);
  setloggedInUserRole(storedLogRole);
  console.log('This is stored ROLE by useState ====> ', loggedInUserRole); 
}, [loggedInUserID, loggedInUserRole])


useEffect(() => {
  axios.get(baseURL1, options).then(res => {
         console.log('Farm Pools', res.data.data);
         const farm = res.data.data;
         setFarmData(farm);
         setFarmCount(farm.length);
     console.log(farmCount);
       })
}, [loggedInUserID, loggedInUserRole])





  // useEffect(() => {
  //   async function getSwitches() {
  //     const tabs = await actions.getSwitches();
  //     setState({ tabs: tabs });
  //   }
  //   getSwitches();

  //   async function updateSwitches() {
  //     const response = await actions.updateSwitches(params.id, {
  //       isActive: params.isActive,
  //     });
  //     notify(response.message, `success`);
  //     setState((prevState) => ({
  //       ...prevState,
  //       tabs: prevState.tabs.map((tab) => ({
  //         ...tab,
  //         isActive: tab.id === params.id ? params.isActive : tab.isActive,
  //       })),
  //     }));
  //   }

  //   if (params) {
  //     updateSwitches();
  //   }
  // }, [params]);

  // const onChange = (isActive, id) => {
  //   setParams({ id: id, isActive: isActive });
  // };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.main}>
          <Card className={classes.root}>
            <CardHeader title='Stake Pools' />
            <CardContent>
              <h1 style={{color: 'white'}}>{stakeCount}</h1>
            </CardContent>
          </Card>
          <Card className={classes.root}>
            <CardHeader title='Farm Pools' />
            <CardContent>
              <h1 style={{color: 'white'}}>{farmCount}</h1>
            </CardContent>
          </Card>
          {/* <Card className={classes.root}>
          <CardHeader title="Total Stake Participants" />
          <CardContent><h1>{totalParticipants || 0}</h1></CardContent>
      </Card>
      <Card className={classes.root}>
          <CardHeader title="Total Farm Participants" />
          <CardContent><h1>{totalParticipants || 0}</h1></CardContent>
      </Card> */}
        </div>

        <div className={classes.second}>
          <div className={classes.subDiv1}>
            <h4 className={classes.HGreen}>Active Pools</h4>
            <hr size='7' color='#001440' className={classes.hr} />
            <div className={classes.SD1Table}>
              <div>
                <Table striped bordered hover>
                  <thead class='text-center'>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>logo</th>
                      <th>Contract Address</th>
                      <th>Lock Duration</th>
                    </tr>
                  </thead>
                  {activePools && activePools.map(Pools => (
                    <tbody key={Pools.id} className='text-center'>
                      <tr>
                        <td> {<Form.Check aria-label='option 1' />} </td>

                        <td> {Pools.name} </td>
                        <td>
                          <img
                            src={Pools.logo}
                            style={{ width: '50px', height: '50px' }}
                          />
                        </td>
                        <td> {Pools.contractAddress} </td>
                        <td> {Pools.lock_duration} </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </div>
            </div>
          </div>

          
          <div className={classes.subDiv2}>
            <h4 className={classes.HRed}>Hidden Pools</h4>
            <hr size='7' color='#001440' className={classes.hr} />
            <div className={classes.SD2Table}>
              <div>
                <Table striped bordered hover>
                  <thead class='text-center'>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>logo</th>
                      <th>Contract Address</th>
                      <th>Lock Duration</th>
                    </tr>
                  </thead>
                  {hiddenPools && hiddenPools.map(Pools => (
                    <tbody key={Pools.id} className='text-center'>
                      <tr>
                        <td> {<Form.Check aria-label='option 1' />} </td>

                        <td> {Pools.name} </td>
                        <td>
                          <img
                            src={Pools.logo}
                            style={{ width: '50px', height: '50px' }}
                          />
                        </td>
                        <td> {Pools.contractAddress} </td>
                        <td> {Pools.lock_duration} </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </div>
            </div>
          </div>
{/* 
          <div className={classes.subDiv3}>
            <h4 className={classes.HRed}>Active Pools</h4>
            <hr size='7' color='#001440' className={classes.hr} />
            <div className={classes.SD3Table}>
              <div>
                <Table striped bordered hover>
                  <thead class='text-center'>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>logo</th>
                      <th>Contract Address</th>
                      <th>Lock Duration</th>
                    </tr>
                  </thead>
                  {activePools1.map(Pools => (
                    <tbody key={Pools.id} className='text-center'>
                      <tr>
                        <td> {<Form.Check aria-label='option 1' />} </td>

                        <td> {Pools.name} </td>
                        <td>
                          <img
                            src={Pools.logo}
                            style={{ width: '50px', height: '50px' }}
                          />
                        </td>
                        <td> {Pools.contractAddress} </td>
                        <td> {Pools.lock_duration} </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </>
  )
}

export default Dashoard
