import axios from "axios";

const API_URL = process.env.REACT_APP_API
// production server
export const api = axios.create({
  // baseURL: "http://api.3.67.57.47.nip.io/api/v1/",
  // : "http://indexapi.43.204.181.147.nip.io/api/v1/",
  baseURL: API_URL,
});

// export const apiUrl = "http://api.3.67.57.47.nip.io/api/v1/";
// export const apiUrl = "http://indexapi.43.204.181.147.nip.io/api/v1/";
export const apiUrl = process.env.REACT_APP_API;

// aws s3 bucket confiurations
export const awsRegion = "eu-central-1";
export const awsIdentityPoolId =
  "eu-central-1:949b9487-ed35-4eb3-a2d3-1866e6ad890b";
export const awsBucket = "avangrat-development";
