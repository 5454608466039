import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Form from 'react-bootstrap/Form';
import { Button } from 'react-admin';
import { EditButton } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import PopUp from './EditStakeFarmPool';
import styled from "styled-components";
import './Style.css';
const API_URL= process.env.REACT_APP_API;


const FarmPoolList = () => {
  const [Pools, setPools] = useState([]);
  const [isCheckBoxClicked, setCheckBoxClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUp, setShowPopUp] = useState(false);
  const [PoolId, setPoolId] = useState(''); 
  const [PoolData, setPoolData] = useState([]);
  const [PoolIdData, setPoolIdData] = useState(null);
  const [LoggedInAddress, setLoggedInAddress] = useState([]);
  const [superAdminAddress, setSuperAdminAdd] = useState(null);
  // const [isPoolDisabled, setPoolDisabled] = useState(false);
  const [poolStatus, setPoolStatus] = useState();
   const [loggedInUserRole, setloggedInUserRole]=useState("");
   const [loggedInUserID, setloggedInUserID] = useState("");


  const handleEditButton = (PoolID) => {
      
        setPoolId(PoolID);
        getPoolById(PoolID) 
        setCheckBoxClicked(true);
        console.log(isCheckBoxClicked)
        console.log('Pool ID ==> ',PoolId);
      // getPoolById(PoolId);
  } 

  
  const getPoolById = (PoolID) => {

    const baseURL2 = `${API_URL}/stakeFarmRoute/getPoolById/${PoolID}`;
    // const baseURL2 = `http://localhost:9000/api/v1/stakeFarmRoute/getPoolById/${PoolID}`;

    console.log('getPollById==>',PoolID);
    // if(PoolID){
          axios.get(baseURL2, options).then((res) => {
            // if(res){
              const poolById = res.data.data;
              console.log(poolById);
              setPoolIdData(poolById[0]); 
              console.log('Pool Data By ID ==>',PoolIdData);
              return PoolIdData;
          }).catch((err)=>{console.log(err);});
}

  const handleDisablePool = async(e) => {
    e.preventDefault();
      // const updateURL = `http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/updatePool/${PoolId}`;
      const updateURL = `${API_URL}/stakeFarmRoute/updatePool/${PoolId}`;

      console.log('Id for update ==> ', PoolId);
      // const updateURL = `http://localhost:9000/api/v1/stakeFarmRoute/updatePool/${PoolId}`;

      axios.put(updateURL, options).then((res) => {
        if(res){
          console.log('Current status value',res.data.data.isDisabled)
          setPoolStatus(res.data.data.isDisabledentStatus);
          if(poolStatus){
            console.log('pool disabled successfully. ...!', poolStatus);
          }
          setPoolId([]);
        }
      }).catch((err)=> {
        console.log(err);
      })
  }
  const displayPopUp = () => {
    setShowPopUp(!isPopUp);
  }
//  const baseURL = "http://localhost:9000/api/v1/stakeFarmRoute/getAllFarmPools";


const baseURL = loggedInUserRole === "SUPERADMIN" ?  `${API_URL}/stakeFarmRoute/getAllFarmPools`:
loggedInUserRole === "ADMIN" ? `${API_URL}/stakeFarmRoute//getAdminFarmPools/${loggedInUserID}` : 
                          "Sorry, no api available" ;




  // const baseURL = "http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/getAllFarmPools";

  // const baseURL = "http://localhost:9000/api/v1/stakeFarmRoute/getAllFarmPools";

  // const getPoolByIDApi = (PoolId) => {  
  //   const baseURL2 = `http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/getPoolById/${PoolId}`;
  // } 

  const superAdminURL = `${API_URL}/SuperAdmin/getSuperAdmin`;
  // const superAdminURL = 'http://localhost:9000/api/v1/SuperAdmin/getSuperAdmin';

  const options = {
    mode: "cors"
  }

  

  const baseURL5 = `${API_URL}/SuperAdmin/status`;
  //getCurrent Logged In Admin User
  const getCurrentAdminUser = () => {
    axios.get(baseURL5).then((res) => {
      console.log('Current Logged In Admin User ---------> ', res);
    });
  };


  const getSuperAdmin = () => {
    axios.get(superAdminURL, options).then((res) => {
      const data = res.data.data
      setSuperAdminAdd(data[0].superAdminWallet);

      console.log('Address from database==>',superAdminAddress);
  
    }).then(err => {
      console.log(err);
    })
  }

const getLoggedInAddress = () => {
  if(window.ethereum){
    window.ethereum.request({method:'eth_requestAccounts'})
      .then(res=>{
        // Return the address of the wallet
        setLoggedInAddress(res);
        // console.log(res[0]) 
        console.log('Address from Metamask==>',LoggedInAddress[0]);
      }).catch(err=>{
        console.log(err)
      })
  }else{
    alert("install metamask extension!!")
  }
}

  useEffect(() => {
    const storedLogID = localStorage.getItem('loggedInID');
    console.log('This is stored data ====> ', storedLogID);
    setloggedInUserID(storedLogID);
    console.log('This is stored ID by useState ====> ', loggedInUserID);
    
    const storedLogRole = localStorage.getItem('roleName');
    console.log('This is stored data ====> ', storedLogRole);
    setloggedInUserRole(storedLogRole);
    console.log('This is stored ROLE by useState ====> ', loggedInUserRole); 
  }, [loggedInUserID, loggedInUserRole])


useEffect(() => {
  axios.get(baseURL, options).then((res) => {
    const poolData = res.data.data;
    console.log('---->>>>',poolData)
    setPools(poolData);
    console.log(Pools);
    if(isCheckBoxClicked){
      console.log(' Pool Status==> ', PoolData);
    }
  });
  }, [loggedInUserID, loggedInUserRole])



  
  useEffect (() => {
    getPoolById(PoolId); 
  }, [isCheckBoxClicked, poolStatus])

  useEffect(() => {
    getSuperAdmin();
    getLoggedInAddress();
    getCurrentAdminUser()
    // getLocalData();
    // getPoolStatus();
    // getPoolById(PoolId);  
  },[]);
  return (


    //toDo --> superadmin and loggedin wallet address same or not?

    <div>
      <Table striped bordered hover >
        <thead class="text-center">
          <tr>
            <th></th>
            <th>Name</th>
            <th>logo</th>
            <th>Contract Address</th>
            <th>Lock Duration</th>
          </tr>
        </thead>
        {Pools.map((Pools) => (
          <tbody key={Pools.id} className="text-center" > 
            <tr className={poolStatus === true ? "disable"   : ''}>
            <td> {<Form.Check aria-label="option 1" onClick={async() => await handleEditButton(Pools.id)} />} </td>
             
              <td> {Pools.name} </td>
              <td>
                <img src={Pools.logo}  style={{width:'50px',height:'50px'}}/>
              </td>
              <td> {Pools.contractAddress} </td>
              <td> {Pools.lock_duration} </td>
            </tr>
          </tbody>
        ))}
      </Table>
      
      <div>
          { isCheckBoxClicked ? <>
          
            <SubmitBtn>
              {' '}
              <button type='submit' onClick={displayPopUp}>Edit Pool</button>{' '}
            </SubmitBtn>

            <SubmitBtn>
              {' '}
              <button type='submit' onClick={handleDisablePool}>Disable Pool</button>{' '}
            </SubmitBtn>
              <div>{isPopUp ? <><PopUp DataByID={PoolIdData} poolID={PoolId} handleClose={displayPopUp}/></> : ''}</div>
          </>  : ''}
      </div>
    </div>
  );
};

const SubmitBtn = styled.div`
  cursor: pointer;
  background-color: #2196f3;
  //   height: 1.5rem;
  width: 10%;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  border: 1px solid #030e67;
  padding: 5px;
  button {
    background: none;
    border: none;
    font-weight: 700;
    font-family: 'Rajdhani';
  }
`

export default FarmPoolList;
