import React, { Component } from "react";
import { Admin, Resource } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import UserIcon from '@material-ui/icons/Group';
import BookIcon from '@material-ui/icons/Book';
import { AccountTreeSharp, Info, Collections, Public, MovieRounded, StarSharp, HomeSharp } from '@material-ui/icons';

import dataProvider from "./authentication/dataProvider";
import AuthProvider from "./authentication/authProvider";
import Dashboard from "./components/dashoboard/dashboard";

import NotFound from "./components/notFound";
import UserList from "./components/users/users";
import UserShow from "./components/users/user.view";
import UserCreate from "./components/users/user.create";
import UserEdit from "./components/users/user.edit";
import CategoriesList from "./components/categories/categories";
import CategoryShow from "./components/categories/category.view";
import CategoryCreate from "./components/categories/category.create";
import CategoryEdit from "./components/categories/category.edit.jsx";
import NFTList from "./components/nft/nfts";
import NFTCreate from "./components/nft/nft.create";
import NFTShow from "./components/nft/nft.view";
import NFTEdit from "./components/nft/nft.edit";
import BannerList from "./components/banner/banners";
import BannerCreate from "./components/banner/banner.create";
import BannerShow from "./components/banner/banner.view";
import BannerEdit from "./components/banner/banner.edit";
import InfoList from "./components/info/infos";
import InfoShow from "./components/info/info.view";
import InfoCreate from "./components/info/info.create";
import InfoEdit from "./components/info/info.edit";

import ProfileInfoList from "./components/profile-info/infos";
import ProfileInfoShow from "./components/profile-info/info.view";
import ProfileInfoCreate from "./components/profile-info/info.create";
import ProfileInfoEdit from "./components/profile-info/info.edit";

import HallFrameInfoList from "./components/hall-frame-info/infos";
import HallFrameInfoShow from "./components/hall-frame-info/info.view";
import HallFrameInfoCreate from "./components/hall-frame-info/info.create";
import HallFrameInfoEdit from "./components/hall-frame-info/info.edit";
import CollectionList from "./components/collection/collections";
import CollectionCreate from "./components/collection/collection.create";
import CollectionShow from "./components/collection/collection.view";
import CollectionEdit from "./components/collection/collection.edit";
import PopularNFTs from "./components/popular-nft/popular.nft";
import PopularNFTShow from "./components/popular-nft/popular-nft.view";
import PopularCollections from "./components/popular-collection/popular-collection";
import PopularCollectionShow from "./components/popular-collection/popular-collection.view";
// import AddStakePool from "./components/pools/StakePoolList";
import StakePoolCreate from "./components/pools/StakePoolCreate";
import StakePoolList from "./components/pools/StakePoolList";
import FarmPoolList from './components/pools/FarmPoolList'
import EditStakeFarmPool from './components/pools/EditStakeFarmPool';

//admin pools
// import adminStakePoolCreate from "./components/admin-pool/StakePoolCreate";
import adminStakePoolList from "./components/admin-pool/StakePoolList";
import adminFarmPoolList from './components/admin-pool/FarmPoolList'
// import adminEditStakeFarmPool from './components/admin-pool/EditStakeFarmPool';
import adminDashboard from './components/admin-pool/dashboard';

import axios from 'axios';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import { services } from "./services";

const history = createHistory();
const API_URL= process.env.REACT_APP_API;
// console.log('------>', API_URL)

const App = () => {

  const authProvider = (type, params) => {
    function getRoles(){
   const baseURL = `${API_URL}/role/getRoles`;
   // const baseURL = 'http://indexapi.43.204.181.147.nip.io/api/v1/role/getRoles';
   axios.get(baseURL).then((res) => {
       console.log('getRoles ==> ',res);
       // localStorage.setItem('roleName',res.data.data[0].roleName); 
   }).catch((err) => {
       console.log('Error ==> ',err);
   });
}

getRoles();





if (type === AUTH_LOGIN) {
   // const superadmin = services.post('admin/login', params, { headers: { "content-type": "application/json", } })
  
   // const superAdminRes = superadmin.then((res) => {
   //     console.log('Inner data loop res==>', res.data.status);
   //     // const data = res.data.status;
   //     // return data;

   //                 if (res.status < 200 || res.status >= 300) {
   //                     throw new Error(res.statusText);
   //                 }
   //                 localStorage.setItem('token', res.data.data.token);
   //                 // localStorage.setItem('token', response.data.data.token);

   // }).catch((e) => {
   //     if (e.response) {
   //                     throw new Error(e.response.data.message)
   //                 } else {
   //                     throw new Error('Network Error')
   //                 }
   // }) ;

   
   // const request = services.post('SuperAdmin/superAdminLogin', params, { headers: { "content-type": "application/json", } }) || services.post('admin/adminLogin', params, { headers: { "content-type": "application/json", } }) ;
   // return request
   //     .then((response) => {
   //         console.log('Login response==>',response);
   //         if (response.status < 200 || response.status >= 300) {
   //             throw new Error(response.statusText);
   //         }
   //         localStorage.setItem('token', response.data.data.token);
   //         // localStorage.setItem('token', response.data.data.token);
   //     })
   //     .catch((e) => {
   //         if (e.response) {
   //             throw new Error(e.response.data.message)
   //         } else {
   //             throw new Error('Network Error')
   //         }
   //     });

      const request = services.post('SuperAdmin/superAdminLogin', params, { headers: { "content-type": "application/json", } })
    return request
       .then((response) => {
           console.log('login response==>',response);
           if (response.status < 200 || response.status >= 300) {
               throw new Error(response.statusText);
           }
            localStorage.setItem('token', response.data.data.token);
           localStorage.setItem('roleName', response.data.data.RoleName);
            localStorage.setItem('loggedInID', response.data.data.loginUserID);

           })

           
      
       .catch((e) => {
           if (e.response) {
               throw new Error(e.response.data.message)
           } else {
               throw new Error('Network Error')
           }
       });
} 
     


if (type === AUTH_LOGOUT) {
   localStorage.removeItem('token');
   localStorage.removeItem('roleName');
   localStorage.removeItem('loggedInID');
   return Promise.resolve();
}

if (type === AUTH_ERROR) {
   const status = params.status;
   if (status === 401 || status === 403) {
       localStorage.removeItem('token');
       localStorage.removeItem('roleName');
       localStorage.removeItem('loggedInID');
         return Promise.reject();
   }
   return Promise.resolve();
}

if (type === AUTH_CHECK) {
   
   return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
   
}

return Promise.resolve();
}

    return (
      <> 
        <Admin
          title="React Admin"
          history={history}
          authProvider={authProvider}
          dataProvider={dataProvider}
          dashboard={Dashboard}
          NotFound={NotFound}
        >
          
          
          <Resource name="View_Stake_Pools" icon={Info} list={StakePoolList} title="StakePools" edit={EditStakeFarmPool} />
          <Resource name="View_Farm_Pools" icon={Info} list={FarmPoolList} title="FarmPools" />
          <Resource name="Add_Pools" list={StakePoolCreate} />
          </Admin>


      </>
    )
  }


export default App;