import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useNotify } from 'react-admin'
const API_URL= process.env.REACT_APP_API;

function StakePoolCreate (props) {
  const inputStyle = {
    // width: 'auto',
    // height: '50px',
    // margin: '10px',
    // outline: 'none',
    // border: 'none' // border-radius: "5px"
  // width: '100%',
  // padding: '12px',
  // border: '1px solid #ccc',
  // borderRadius: '4px',
  // resize: 'vertical',
  }

  const [isFarmPool, showFarmPool] = useState(false);
  // const [selectValue, setSelectValue] = useState('Pool Type');
  const [itWorks, setItWorks] = useState(false);
  const [roleId, setRoleId] = useState(false);
  
  const [formvalue, setFormvalue] = useState({
    name: '',
    logo: '',
    contractAddress: '',
    PoolType: '',
    lpTokenAddress: '',
    roleName: '',
    roleID: ''
  });
  const [formerror, setFormerror] = useState({});
  const [issubmit, setsubmit] = useState(false);
  const notify = useNotify();

  function handleClicked () {
    setItWorks(true);
  }

  function handleToggleClicked () {
    setItWorks(false);
  }

  const handleClick = () => {
    notify(`Form submitted`, `success`);
  }

  const handleError = (err) => {
    if(err){
      notify(`Form not submitted`, `error`);
    }
  }

  // HandelValidation
  const HandelValidation = e => {
    const { name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value })
    console.log(formvalue);
    if (formvalue.PoolType === 'farm') {
      showFarmPool(true)
    } else {
      return isFarmPool;
    }
  }

  const baseUrl =
    `${API_URL}/stakeFarmRoute/addStakePool`;
    // 'http://localhost:7000/api/v1/stakeFarmRoute/addStakePool'

  //   HandelSubmit function
  const handleSubmit = e => {
    e.preventDefault();
    e.target.reset();
    setFormvalue('');
    // setFormerror(validationform(formvalue));
    setsubmit(true);

    console.log('Form Value Role ID == >',formvalue.roleID);

    axios
      .post(  
        baseUrl, //To fetch Api
        {
          name: formvalue.name,
          logo: formvalue.logo,
          contractAddress: formvalue.contractAddress,
          PoolType: formvalue.PoolType,
          lpTokenAddress: formvalue.lpTokenAddress,
          roleName: formvalue.roleName,
          roleID: roleId
        }
      )
      .then(res => {
        handleClick();
        console.log("success, dictionary sent,", res);
      })
      .catch(err => {
        console.log(err.response);
        handleError(err);
      })
  }

  const getLogedInID = async() => {
      const id = await localStorage.getItem('loggedInID');
      setRoleId(id);
      // setFormvalue(formvalue.roleID = roleId)
  }

  useEffect(() => {
    getLogedInID();
  }, [roleId]);

  useEffect(() => {
    if (Object.keys(formerror).length === 0 && issubmit) {
        console.log(formvalue);
    }
  }, [formerror, formvalue, issubmit])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FlexDiv>
          <ContainerDiv>

            <WrapContainer>
              <Label>Name :</Label>
              <Input>
                <input
                  type='name'
                  className='inputtag'
                  name='name'
                  onChange={HandelValidation}
                  value={formvalue.name}
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
              <Label>Logo :</Label>
              <Input >
                <input
                  type='logo'
                  className='inputtag'
                  name='logo'
                  onChange={HandelValidation}
                  value={formvalue.logo}
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
              <Label>Role Name :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='roleName'
                  onChange={HandelValidation}
                  value={formvalue.RoleName}
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
              <Label>Contract Address :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='contractAddress'
                  onChange={HandelValidation}
                  value={formvalue.contractAddress}
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
            <Label>Pool Type :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='PoolType'
                  onChange={HandelValidation}
                  value={formvalue.PoolType}
                  placeholder="Enter Pool Type only as stake or farm"
                />
              </Input>
            </WrapContainer>

            <WrapContainer>
              <Label>LP Token Address :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='lpTokenAddress'
                  onChange={HandelValidation}
                  value={formvalue.lpTokenAddress}
                />
              </Input>
            </WrapContainer>

            {itWorks ? (
              <>
                {/* <Label>LP TokenAddress :</Label>
                <input
                  type='text'
                  className="inputtag"
                  style={inputStyle}
                  name="lpTokenAdderss"
                  onChange={HandelValidation}
                  value={formvalue.lpTokenAddress}
                /> */}
            <WrapContainer>
              <Label>LP Token Address :</Label>
              <Input>
                <input
                  type='text'
                  className='inputtag'
                  name='lpTokenAddress'
                  onChange={HandelValidation}
                  value={formvalue.lpTokenAddress}
                />
              </Input>
            </WrapContainer>
              </>
            ) : (
              ''
            )}

            {/* <WrapContainer>
              <Label>Pool Type :</Label>
              <Select defaultValue={selectValue} >
                <option value="Orange">Stake</option>
                <option value="Radish">Farm</option>
              </Select>
            </WrapContainer> */}

            <SubmitBtn>
              {' '}
              <button type='submit'>Submit</button>{' '}
            </SubmitBtn>
          </ContainerDiv>
        </FlexDiv>
      </form>
    </>
  )
}

//  css start styled components
const FlexDiv = styled.div`
  background: #b2c8df;
  border-radius: 3px;
  border: 2px solid #2196f3;
  margin: 5em 1em;
  padding: 0.25em 1em;
  // width:100%;
  height: auto;
  margin-left:10%;
  margin-right:10%
`

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .message {
    font-size: 1.2em;
    text-align: center;
    color: #36a936;
  }
`
const WrapContainer = styled.div`
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  width:95%;
`
const Label = styled.div`
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
`

const Input = styled.div`
  .inputtag {
    // width: 100%,
    // padding: 12px,
    // border: 1px solid #ccc,
    // borderRadius: 4px,
    // resize: vertical,

    width:100%;
    height: 45px;
    margin: 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    :focus {
      border: 1px solid #2196f3;
    }

  }

  width:100%;

`

const RadioBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  .input {
    background-color: red;
  }
`

const SubmitBtn = styled.div`
  cursor: pointer;
  background-color: #2196f3;
  //   height: 1.5rem;
  width: auto;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  border: 1px solid #030e67;
  padding: 5px;
  button {
    background: none;
    border: none;
    font-weight: 700;
    font-family: 'Rajdhani';
  }
`

// const Select = styled.select`
//   // width: 100%;
//   // height: 35px;
//   // background: white;
//   // color: gray;
//   // padding-left: 5px;
//   // font-size: 14px;
//   // border: none;
//   // margin-left: 10px;

//   width: 500%;
//     height: 50px;
//     margin: 10px;
//     outline: none;
//     border: none;
//     border-radius: 5px;
//     :focus {
//       border: 1px solid #2196f3;
//     }

//   option {
//     color: black;
//     background: white;
//     display: flex;
//     white-space: pre;
//     min-height: 20px;
//     padding: 0px 2px 1px;
//   }
// `;

export default StakePoolCreate
